:root {
  font-size: 16px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

* {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

h6 a,
p a {
  color: #2660df !important;
  text-decoration-color: #2660df !important;
}

.link-orange {
  color: #f98a3c !important;
}

@media only screen and (max-width: 768px) {
  .MuiTypography-body2 {
    font-size: 1rem !important;
  }
}
